<template>
  <div>
    <div class="mb-3">
      <Breadcrumbs :items="items" />
    </div>
    <tablaComponent
      ref="tabla"
      :headers="headers"
      :titulo-tabla="tituloTabla"
      :url="url"
      :cargando="cargando"
      :have-actions="haveActions"
      :have-add="haveAdd"
      :have-edit="haveEdit"
      :have-delete="haveDelete"
      :search="search"
      :titulo-search="tituloSearch"
      @nuevoform="abrirForm()"
      @editform="editItem($event)"
      @deleteitem="deleteItem($event)"
      @dialogSearch="cargarSearch()"
      @refreshSearch="refreshSearch()"
    >
      <template v-slot:filterSearch>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="filterFilesSelected"
              :items="filterFiles"
              item-value="id"
              item-text="name"
              outlined
              dense
              chips
              small-chips
              label="Filtrar por"
              multiple
              hide-details
            >
              <template v-slot:selection="data">
                <v-chip
                  class="elevation-2 my-1"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeFilterFilesSelected(data.item)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <!-- Nombre -->
          <v-col
            v-if="isNombreSelected"
            cols="12"
          >
            <v-text-field
              v-model="nombreCategoriaSearch"
              label="Nombre categoría"
              outlined
              dense
              placeholder="Buscar por nombre de la categoría"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            v-if="isNombreSelected"
            cols="12"
          >
            <v-text-field
              v-model="nombreSubCategoriaSearch"
              label="Nombre subcategoría"
              outlined
              dense
              placeholder="Buscar por nombre de la subcategoría"
              hide-details
            ></v-text-field>
          </v-col>
          <!-- Code -->
          <v-col
            v-if="isCodeSelected"
            cols="12"
          >
            <v-text-field
              v-model="codeCategoriaSearch"
              label="Código categoría"
              outlined
              dense
              placeholder="Buscar por código de la categoría"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            v-if="isCodeSelected"
            cols="12"
          >
            <v-text-field
              v-model="codeSubCategoriaSearch"
              label="Código subcategoría"
              outlined
              dense
              placeholder="Buscar por código de la subcategoría"
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
    </tablaComponent>
    <!-- Formulario de Confirmación -->
    <confirm ref="confirm"></confirm>
    <v-snackbar
      v-model="notificaciones.snackbar"
      :bottom="notificaciones.y === 'bottom'"
      :color="notificaciones.color"
      :left="notificaciones.x === 'left'"
      :multi-line="notificaciones.mode === 'multi-line'"
      :right="notificaciones.x === 'right'"
      :timeout="notificaciones.timeout"
      :top="notificaciones.y === 'top'"
      :vertical="notificaciones.mode === 'vertical'"
    >
      {{ notificaciones.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="notificaciones.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { ref, reactive, computed } from '@vue/composition-api'
import HTTP from '@/utils/axios/axios-config-base'
import { useRouter } from '@/utils'
import tablaComponent from '@/components/tablaComponent.vue'
import Confirm from '@/components/Confirm.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  components: { tablaComponent, Confirm, Breadcrumbs },
  created() {
    if (this.$route.params.notificaciones != null) {
      this.notificaciones.snackbar = this.$route.params.notificaciones.snackbar
      this.notificaciones.text = this.$route.params.notificaciones.text
      this.notificaciones.color = this.$route.params.notificaciones.color
    }
  },
  setup() {
    const { router } = useRouter()
    const tituloTabla = ref('Subcategorías')
    const url = ref(`${HTTP.defaults.baseURL}events-api/event-subcategories`)
    const confirm = ref(null)
    const cargando = ref(false)
    const haveActions = ref(true)
    const headers = ref([
      {
        text: 'Nombre', align: 'start', sortable: false, value: 'subCategoryName',
      },

      // { text: 'Código', value: 'subCategoryCode' },
      { text: 'Categoría', value: 'categoryName' },
    ])
    const items = reactive([
      {
        text: 'Inicio',
        disabled: false,
        href: '/',
      },
      {
        text: `${tituloTabla.value}`,
        disabled: true,
        href: '/gestion-subcategorias-eventos',
      },
    ])
    const tabla = ref(null)
    const search = ref({})
    const tituloSearch = ref('Buscar Subcategorías')
    const filterFiles = [
      // { id: 'code', name: 'Código' },
      { id: 'nombre', name: 'Nombre' },

    ]
    const filterFilesSelected = ref([])
    const isNombreSelected = computed(() => filterFilesSelected.value.includes('nombre'))
    const nombreCategoriaSearch = ref('')
    const nombreSubCategoriaSearch = ref('')
    const isCodeSelected = computed(() => filterFilesSelected.value.includes('code'))
    const codeCategoriaSearch = ref('')
    const codeSubCategoriaSearch = ref('')
    const notificaciones = reactive({
      snackbar: false,
      color: '',
      mode: '',
      text: '',
      timeout: 3000,
      x: null,
      y: 'top',
    })

    const permissions = localStorage.getItem('permissions')
    const haveDelete = computed(() => permissions.includes('Events:Categories:FullAccess') || permissions.includes('Events:Categories:Delete'))
    const haveEdit = computed(() => permissions.includes('Events:Categories:FullAccess') || permissions.includes('Events:Categories:ReadEditAccess'))
    const haveAdd = computed(() => permissions.includes('Events:Categories:FullAccess') || permissions.includes('Events:Categories:ReadEditAccess'))

    return {
      headers,
      router,
      tituloTabla,
      url,
      cargando,
      haveActions,
      haveAdd,
      haveEdit,
      haveDelete,
      confirm,
      items,
      tabla,
      search,
      tituloSearch,
      filterFiles,
      filterFilesSelected,
      isNombreSelected,
      nombreCategoriaSearch,
      nombreSubCategoriaSearch,
      isCodeSelected,
      codeCategoriaSearch,
      codeSubCategoriaSearch,
      notificaciones,
      cargarSearch: () => {
        const searchAll = {}
        if (filterFilesSelected.value.includes('nombre')) {
          if (nombreCategoriaSearch.value !== '') {
            searchAll.CategoryName = nombreCategoriaSearch.value
          }
          if (nombreSubCategoriaSearch.value !== '') {
            searchAll.SubCategoryName = nombreSubCategoriaSearch.value
          }
        }
        if (filterFilesSelected.value.includes('code')) {
          if (codeCategoriaSearch.value !== '') {
            searchAll.CategoryCode = codeCategoriaSearch.value
          }
          if (codeSubCategoriaSearch.value !== '') {
            searchAll.SubCategoryCode = codeSubCategoriaSearch.value
          }
        }
        search.value = searchAll
      },
      refreshSearch: () => {
        filterFilesSelected.value = []
        search.value = {}
      },
      removeFilterFilesSelected: async item => {
        const index = filterFilesSelected.value.indexOf(item.id)
        if (index >= 0) filterFilesSelected.value.splice(index, 1)
      },
      abrirForm: () => {
        cargando.value = true
        setTimeout(() => {
          router.push(
            {
              name: 'gestion_subcategorias_eventos_form',
              params: {
                obj: null,
              },
            },
          )
          cargando.value = false
        }, 500)
      },
      editItem: item => {
        cargando.value = true
        setTimeout(() => {
          const items = [
            {
              text: 'Inicio',
              disabled: false,
              href: '/',
            },
            {
              text: 'Subcategorías',
              disabled: false,
              href: '/gestion-subcategorias-eventos',
            },
            {
              text: 'Editar Subcategoría',
              disabled: true,
              href: '/gestion-subcategorias-eventos/form',
            },
          ]
          router.push(
            {
              name: 'gestion_subcategorias_eventos_form',
              params: {
                obj: item,
                formTitulo: 'Editar Subcategoría',
                items,
              },
            },
          )
          cargando.value = false
        }, 500)
      },
      deleteItem: async item => {
        confirm.value
          .open(
            'Eliminar cubcategoría',
            '¿Está seguro que desea eliminar la subcategoría seleccionada?',
            {
              color: 'primary',
              width: '450',
            },
          )
          .then(confirm => {
            if (confirm) {
              const URL = `${HTTP.defaults.baseURL}events-api/event-subcategories/${item.subCategoryId}`
              const resp = HTTP.delete(URL)
              resp.then(val => {
                if (val.status === 200) {
                  notificaciones.color = 'success'
                  notificaciones.text = 'El registro se ha eliminado satisfactoriamente'
                  notificaciones.snackbar = true
                  tabla.value.listar()
                } else {
                  notificaciones.color = 'error'
                  notificaciones.text = 'Ha ocurrido un error mientras se eliminaba el registro'
                  notificaciones.snackbar = true
                }
              })
            } else {
              notificaciones.color = 'info'
              notificaciones.text = 'Operación cancelada'
              notificaciones.snackbar = true
            }
          })
      },
    }
  },
}
</script>

<style>

</style>
